import React, { useMemo } from 'react'
import SVG from 'react-inlinesvg'

import { EmailNotificationLayout } from '../../../components/email-notification'
import { formatStat, Trend } from '../../../components/shared'

const ITEMS = [
  {
    title: 'Pageviews',
    icon: 'pageviews',
    value: 240.28,
    valueTrend: 0.2,
    relevantTitle: 'EPM',
    relevantValue: 61366,
    relevantValueType: 'DKK',
  },
  {
    title: 'Conversions',
    icon: 'conversions',
    value: 2.34,
    valueTrend: 0.9,
    relevantTitle: 'CR',
    relevantValue: 0.4,
    relevantValueType: 'trend2',
  },
  {
    title: 'Clicks',
    icon: 'clicks',
    value: 50.932,
    valueTrend: 1.9,
    relevantTitle: 'CTR',
    relevantValue: 0.4,
    relevantValueType: 'trend2',
  },
  {
    title: 'Revenue',
    icon: 'revenue',
    value: 50.932,
    valueTrend: -1.5,
    relevantTitle: 'EPC',
    relevantValue: 535,
    relevantValueType: 'DKK',
  },
]

export default function MonthlyReport({ location }) {
  const queryParams = new URLSearchParams(location.search)
  const type = queryParams.get('type')

  const firstCardClass = useMemo(() => {
    if (type === 'expanded') {
      return 'card-green'
    }
    return 'card-yellow'
  }, [type])

  const secondCardClass = useMemo(() => {
    if (type === 'expanded') {
      return 'card-gray'
    }
    return 'card-yellow'
  }, [type])

  const reportItems = useMemo(() => {
    if (type === 'expanded') {
      return ITEMS
    }
    return []
  }, [type])

  return (
    <>
      <EmailNotificationLayout>
        <div className="column column-center">
          <div className="email-notification-content">
            <h1 className="text-largiestest text-bold text-dark m-0">
              Monthly report
            </h1>
            <p className="text-center text-smaller text-dark p-x-20">
              We're back with some mind-blowing updates from your affiliate
              marketing universe! Brace yourself for some seriously impressive
              results.
            </p>
            <div className={`report-card card-large ${firstCardClass}`}>
              <div className="flex-1">
                <h2 className="text-big text-bold">
                  Best performing advertiser
                </h2>
                <p className="text-tiny text-primary">
                  Your traffic to X advertisers generated X Commission in *month
                </p>
              </div>
              <div className="row row-center w-50 flex-noshrink">
                <SVG
                  src="/images/email-notifications/arrow.svg"
                  width={14}
                  height={11}
                />
                <div className="column gap-1">
                  <h2 className="text-big text-bold m-0">Adidas</h2>
                  <p className="text-response text-regular text-bold m-0">
                    300.200,00 <span>DKK</span>
                  </p>
                </div>
              </div>
            </div>
            <div className={`report-card card-medium ${firstCardClass}`}>
              <div className="flex-1">
                <h2 className="text-big text-bold">Best performing page</h2>
                <p className="text-tiny text-primary">
                  Your traffic to X advertisers generated X Commission in *month
                </p>
              </div>
              <div className="row row-center w-50 flex-noshrink">
                <SVG
                  src="/images/email-notifications/arrow.svg"
                  width={14}
                  height={11}
                />
                <div className="column gap-1 w-0 flex-1">
                  <h2 className="text-smaller text-bold text-underline text-breakword m-0 p-r-4">
                    demo1.heylink.com/blog/how-to- improve-sound-of-headphones/
                  </h2>
                  <p className="text-response text-regular text-bold m-0">
                    100.500,00 <span>DKK</span>
                  </p>
                </div>
              </div>
            </div>
            <h1 className="text-big text-bolder m-t-40 m-b-0">
              Recommendations of the month
            </h1>
            <p className="text-smalliest text-center text-primary p-x-50 m-0">
              You could be making even more money with Heylink! This past month
              you had X amount of clicks could have been monetised. This could
              have made you X DKK
            </p>
            <div className="row row-middle gap-30 w-100">
              <div className="row">
                <SVG
                  src="/images/overview2/top-clicks.svg"
                  width={40}
                  height={40}
                />
                <div className="column column-space-between column-fill p-y-05">
                  <p className="text-smaller text-boldest m-0">
                    223.625 clicks
                  </p>
                  <p className="text-smaller m-0 text-primary">
                    un-monetised clicks
                  </p>
                </div>
              </div>
              <div className="row">
                <SVG
                  src="/images/email-notifications/monthly-report-dalar.svg"
                  width={40}
                  height={40}
                />
                <div className="column column-space-between column-fill">
                  <p className="text-smaller text-boldest m-0">
                    488,044.64 DKK
                  </p>
                  <p className="text-smaller m-0 text-primary">
                    Missed commissions
                  </p>
                </div>
              </div>
            </div>
            <h1 className="text-big text-center text-bolder p-x-30 m-t-50">
              We found these opportunities <br />
              for super charging your commission:
            </h1>
            <div className="row">
              <div className="w-40 flex-1">
                <div
                  className={`report-card card-small m-b-10 ${secondCardClass}`}
                >
                  <div className="flex-1">
                    <h2 className="text-regular text-bold">
                      Campaign opportunity
                    </h2>
                    <p className="text-more-tiny text-bold text-primary">
                      Apply to X Campaigns on your existing Network accounts to
                      monetise on clicks you are already sending traffic through
                    </p>
                  </div>
                  <p className="text-blue text-regular text-large text-bold">
                    12
                  </p>
                </div>
                <div className={`report-card card-small ${secondCardClass}`}>
                  <div className="flex-1">
                    <h2 className="text-regular text-bold">
                      New
                      <br /> Networks
                    </h2>
                    <p className="text-more-tiny text-bold text-primary">
                      Connect to X Networks to monetise on clicks you are
                      already sending traffic through
                    </p>
                  </div>
                  <p className="text-blue text-regular text-large text-bold">
                    44
                  </p>
                </div>
              </div>
              <div
                className={`report-card card-small m-b-10 w-40 flex-1 ${secondCardClass}`}
              >
                <div className="flex-1">
                  <h2 className="text-regular text-bold">Broken links</h2>
                  <p className="text-more-tiny text-bold text-primary">
                    Fix X broken links, to monetize on users who are already
                    clicking on your links
                  </p>
                </div>
                <p className="text-red text-regular text-large text-bold">
                  321
                </p>
              </div>
            </div>
            <h1 className="text-big m-t-50 m-b-0">
              Summary performance for Month
            </h1>
            <p className="text-smaller text-center text-dark p-x-20 m-0 m-b-30">
              Your hard work paid off with great results! Across your X channels
              you had X Pageviews and X Clicks. Your traffic to X Advertisers
              made Total commission, with an average CR X% and CTR Y%.
            </p>
          </div>
          <div className="report-items">
            {reportItems.map((item, key) => (
              <ReportItem
                key={key}
                title={item.title}
                icon={item.icon}
                value={item.value}
                valueTrend={item.valueTrend}
                relevantTitle={item.relevantTitle}
                relevantValue={item.relevantValue}
                relevantValueType={item.relevantValueType}
              />
            ))}
          </div>
        </div>
      </EmailNotificationLayout>
    </>
  )
}

export const ReportItem = (props) => {
  const {
    title,
    icon,
    value,
    valueType,
    valueTrend,
    relevantTitle,
    relevantValue,
    relevantValueType,
    isEmpty,
  } = props

  const noData = isEmpty && !value && !valueTrend && !relevantValue

  return (
    <div className="card card-nooverflow report-item p-x-5 p-b-3">
      <div className="column column-space-between column-fill h-100">
        <div className="row row-fill">
          <SVG src={`/images/overview2/top-${icon}.svg`} className="m-r-3" />
          <div className="flex-1">
            <div className="m-b-2">
              <div className="row row-center row-space-between row-fill overview-top-header">
                <div className="text-light text-bold">{title} </div>
                <Trend valuePercentage={valueTrend} />
              </div>
            </div>
            <div>
              <span className="text-dark text-larger text-boldest text-nowrap m-r-1">
                {value}
              </span>
            </div>
          </div>
        </div>
        <div>
          <hr className="m-t-4 m-b-10" />
          <span className="row row-center row-space-between text-light p-r-20">
            <div>{relevantTitle}</div>
            <div>{formatStat(relevantValue, relevantValueType)}</div>
          </span>
        </div>
      </div>
      {noData && (
        <div className="awaiting">
          <SVG src="/images/overview2/awaiting.svg" />
        </div>
      )}
    </div>
  )
}
